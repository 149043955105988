
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../store/AppContext';
import assignmentService from '../../../services/AssignmentService';
import ReviewChecklist from './ReviewChecklist';
import assignmentDownloaderService, { DOWNLOADER_STATES } from '../../../services/AssignmentDownloaderService';
import FilterSorterBtn from '../filter_sorter/FilterSorterBtn';
import ActionButtons, { ICONS, BasicActionButton, CopyToClipboardButton} from '../../elements/ActionButtons';
import PullStudentSEMSettings from '../../pull_student/PullStudentSEMSettings';
import DownloaderModal from './DownloaderModal';
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';
import useStudent from '../../../hooks/useStudent';
import LoadingSpinner from '../../modal_assets/LoadingSpinner';

const ReviewEditor = ( { 
    setReviewStatus,
    weekNumber,
    close,
    studentStatuses,
    assignment = null, 
    student: studentOrig = null
} ) => { 

    const { user } = useContext(AppContext);
    const { pushOverlay, popOverlay, updateOverlay, listenForUpdates} = useOverlays();
    const { updateStudent } = useStudent();

    const [ review, setReview ] = useState(null);
    const [ reviewsHistory, setReviewsHistory ] = useState(null); // [ {review}, {review}
    const [ checklist, setChecklist ] = useState(null)
    const [ commentToStudent, setCommentToStudent ] = useState("");
    const [ commentToInstructor, setCommentToInstructor ] = useState("");
    const [ needsFollowUp, setNeedsFollowUp ] = useState(false);
    const [ assignmentDownloaderStatus, setAssignmentDownloaderStatus ] = useState(DOWNLOADER_STATES.NOT_INSTALLED);
    const [ isGeneric, setIsGeneric ] = useState(false);
    const [ displayWorking, setDisplayWorking ] = useState(false);
    const [ checkNoReview, setCheckNoReview ] = useState(false);
    const [ displayDownloaderModal, setDisplayDownloaderModal ] = useState(false);
    const [ student, setStudent ] = useState(studentOrig);

    useEffect(() => { 

        assignmentDownloaderService.getStatus( status => setAssignmentDownloaderStatus(status) );
        
        setDisplayWorking(true);
        assignmentService.get_review(student.id, assignment.chapter_module_id, student.cohort_start_date_short)
            .then( data => {
                setChecklist(data['checklist']);
                setReviewsHistory(data['history']);
                setReview(data['review'])
                setIsGeneric(data['is_generic'])
                // setCommentToStudent(data['review'] ? data['review']['comment_to_student'] : "")
                // setCommentToInstructor(data['review'] ? data['review']['comment_to_instructor'] : "")
                setNeedsFollowUp(data['review'] ? data['review']['needs_followup'] : false)
            })
            .catch( err => console.log(err))
            .finally( () => setDisplayWorking(false))

        listenForUpdates('review_editor', (data) => {
            setStudent(data.student);
            updateOverlay('student_sem_settings', data);
         })
    }, [])

    useEffect(() => { console.log(student)
        updateOverlay('student_sem_settings', { 
            student});
    }, [student])

    const saveReview = ( e, notifyStudent, quedToSend = true ) => {

        // replace newlines in comments with <br/>
        var modifiedChecklist = {...checklist};
        for ( let areaName in modifiedChecklist ) { 
            let area = modifiedChecklist[areaName];
            console.log(area)
            for ( let cat = 0; cat < area.length; cat ++) { 
                if (area[cat]['comment'] === undefined) continue;
                area[cat]['comment'] = modifiedChecklist[areaName][cat]['comment'].replaceAll('\n', '<br/>')
            }
        }
        console.log(modifiedChecklist)

        let reviewText = "Confirm Submission<br/><br/>";
        let feedbacks = ['N/A', 'Good', 'Needs Improvement'];
        let fullInstructorComment = `${commentToInstructor.replaceAll('\n', '<br/>')} ${checkNoReview ? 'Checked / Not Reviewed' : ''}`

        // create message for confirm
        for ( let key of Object.keys(modifiedChecklist) ) {

            let areaFeedbacks = [];
            modifiedChecklist[key].forEach( row => { 
                if ( row['feedback']  ) { 
                    areaFeedbacks.push(`${row['category'].padEnd(30, '.')}:${feedbacks[row['feedback']].padEnd(20, '.')}:${row['comment'] ? row['comment'] : ''} <br/>`)
                }
            })

            if ( areaFeedbacks.length ) {

                reviewText += `${key}: \n`
   
                reviewText += areaFeedbacks.join('');
   
                reviewText += '\n';
            }
        }

        reviewText += `Note to Student:\n${commentToStudent ? commentToStudent : "N/A"} \n\n`;
        reviewText += `Note to Instructor:\n${fullInstructorComment ? fullInstructorComment : "N/A"} \n\n`;
        reviewText += `Needs Follow-Up: ${needsFollowUp || ! quedToSend ? 'Yes': 'No'}\n`;
        reviewText += `Send To: ${notifyStudent ? "Student and Instructor" : !quedToSend ? "NO EMAILS WILL BE SENT " : "INSTRUCTOR ONLY"}`;
        reviewText = reviewText.replaceAll('\n', '<br/>');
        
        pushOverlay({
            type: OVERLAY_TYPES.CONFIRM,
            position: { x: e.clientX, y: e.clientY },
            component: <div dangerouslySetInnerHTML={{__html: reviewText}}></div>,
            respond: (response) => {
                if (response) {

                    setDisplayWorking(true);
            
                    assignmentService.save_review( {
                        student_id: student.id,
                        assignment_id: assignment.chapter_module_id,
                        cohort_start: student.cohort_start_date_short,
                        checklist: JSON.stringify(modifiedChecklist),
                        comment_to_student: commentToStudent.replaceAll('\n', '<br/>'),
                        comment_to_instructor: fullInstructorComment,
                        needs_followup: needsFollowUp,
                        notify_student: notifyStudent,
                        assignment_name: assignment.title,
                        qued_to_send: quedToSend,
                        cohort_id: student.cohort_id,
                        program_name: student.program_name,
                        stack_name: student.stack_name,
                    } )
                        .then( review => {
                            setReviewStatus(assignment.chapter_module_id, needsFollowUp ? 4 : 3);
                            close();
                        } )
                        .catch( err => console.log(err) )
                    
                    
                    // set substantive interaction flag
                    if ( notifyStudent ) {
                        //studentOrig.notify_student = true;
                        studentOrig[`week_${weekNumber}_substantive_interaction`] = true;
                    } 

                    // update assignment status 
                    let updatedAssignments = {...studentOrig.assignments};
                    
                    for (let st_assignment_id in studentOrig.assignments) { console.log(assignment.chapter_module_id, st_assignment_id)
                        let st_assignment = studentOrig.assignments[parseInt(st_assignment_id)];
                        if (parseInt(st_assignment_id) === assignment.chapter_module_id) { console.log("HIT")
                            st_assignment.status = 3;
                            st_assignment.review = {
                                notify_student: notifyStudent,
                            };
                        }
                    }

                    studentOrig.assignments = updatedAssignments;
                    updateStudent(studentOrig);
                    console.log(studentOrig)
                }
            }
        })
    }

    const onDownloadAssignment = (cohortStartDate, assignmentTitle, student, assignment) => {
        
        assignmentDownloaderService.downloadAssignments(
            `${user['first_name']}}`,
            user['id'],
            cohortStartDate,
            assignmentTitle,
            [
                {
                    studentId: student['id'],
                    studentFullName: `${student['first_name']} ${student['last_name'].substring(0,3)}`,
                    submitDate: assignment['submit_date'],
                    fileUrl: assignment['file_url']
                }
            ]
        )
    }

    const fileLocation = assignment['file_url'].includes('github.com') ? 'github' : 'download';
    const hasHistory = reviewsHistory && reviewsHistory.length > 0;

   return (
        <>
            <div className='review_editor row layer layer-3 p-2 window_1200'>
                <div className='col-12'>
                    {
                        checklist === null ? 'Loading Review...' 
                        :
                        <div className='row'>

                            <div className='col-12 text-right'>
                                <ActionButtons
                                    buttons={[
                                        BasicActionButton({
                                            icon: ICONS.CANCEL,
                                            toolTip: 'Close',
                                            onClick: close
                                        })
                                    ]}
                                    />
                            </div>
                            
                            <div className='col-6 review_editor__header'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <FilterSorterBtn
                                            openTab={() => { window.open(`https://login.codingdojo.com/m/${assignment['track_id']}/${assignment['chapter_id']}/${assignment['chapter_module_id']}`, '_blank')}}
                                            openTabTooltip='Open Assignment on the learning Platform'

                                            github={
                                                fileLocation === 'github' ?
                                                    () => { window.open(assignment['file_url'], '_blank')}
                                                    : null
                                            }
                                            githubTooltip='Open Assignment on Github'

                                            downloadFiles={() => {
                                                if ( 
                                                    assignmentDownloaderStatus === DOWNLOADER_STATES.NOT_INSTALLED ||
                                                    assignmentDownloaderStatus === DOWNLOADER_STATES.NOT_CHROME) 
                                                        setDisplayDownloaderModal(true)
                                                else if (fileLocation === 'download')
                                                    onDownloadAssignment(student.cohort_start_date_short, assignment.title, student, assignment)
                                                  
                                            }}
                                            downloadFilesTooltip='Download Assignment'
                                            />

                                        
                                    </div>

                                    <div className='col-10'>
                                        <div className='review_editor__header__title'>
                                            {assignment.title}
                                        </div>
                                        <div className=''>
                                            <ActionButtons
                                                buttons={[
                                                    CopyToClipboardButton({
                                                        icon: ICONS.EMAIL,
                                                        clipboardContent: student.email_address,
                                                        toolTip: 'Copy Student Email Address'
                                                    }),
                                                    BasicActionButton({
                                                        icon: ICONS.TOGGLE,
                                                        toolTip: 'Student SEM Settings',
                                                        onClick: (e) => 
                                                            pushOverlay({
                                                                position: {x: e.clientX, y: e.clientY},
                                                                component: <PullStudentSEMSettings
                                                                    student={student}
                                                                    studentStatuses={studentStatuses}
                                                                    updateStudent={updateStudent}
                                                                />
                                                            })
                                                         ,
                                                    })
                                                ]}
                                                />
                                            <span className='ml-2'>
                                                {student.first_name} {student.last_name}
                                            </span>
                                        </div>
                                        <div className=''>
                                            @ {assignment['submit_date']}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6'>
                                <div className='row'>
                                    <div className='col-12'>
                                        Last Reviewed By:
                                    </div>
                                    <div className='col-12'>
                                        {
                                            review === null ?
                                            <>Not Yet Reviewed</>
                                            :
                                            <>

                                                <FilterSorterBtn
                                                    emailAddress={review.reviewer_email_address}
                                                />

                                                &nbsp;{review.reviewer} 
                                                
                                                <div>
                                                    @ {review.date_added}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='col-12'>
                                <div className='row'>
                                <div className='col-12 mt-2'>
                                <div className='row review_editor__comments'>
                                    <div className='col-6'>
                                        Note to Student:
                                        <span className='ml-3'>
                                        {
                                            (hasHistory) &&
                                                <ActionButtons
                                                    buttons={[
                                                            BasicActionButton({
                                                                icon: ICONS.HISTORY,
                                                                toolTip: 'Reviews History',
                                                                onClick: (e) => 
                                                                    pushOverlay({
                                                                        position: {x: e.clientX, y: e.clientY},
                                                                        component: <HistoryReview
                                                                            reviewsHistory={reviewsHistory}
                                                                        />,
                                                                    })
                                                                 ,
                                                            })
                                                        ]}
                                                    />
                                        }
                                        </span>
                                        <textarea 
                                            className='form-control' 
                                            onChange={(e)=> setCommentToStudent(e.target.value)}
                                            value={commentToStudent}
                                            placeholder={hasHistory && review ? `Last Comment: ( begin typing to remove ) \n${review.comment_to_student}` : null}
                                        ></textarea>
                                    </div>

                                    <div className='col-6'>
                                        Note to Instructor:
                                        <textarea 
                                            className='form-control ' 
                                            onChange={(e)=> setCommentToInstructor(e.target.value)}
                                            value={commentToInstructor}
                                            placeholder={hasHistory && review ? `Last Comment: ( begin typing to remove )  \n${review.comment_to_instructor}` : null}
                                            ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='col-3 text-center mt-2'>
                                
                                <button
                                    className='btn btn-sm btn-danger'
                                    onClick={(e) => saveReview(e, true)}
                                    disabled={displayWorking || ! commentToStudent.length}
                                    >Save And Send To Student</button>
                            </div>
                            <div className='col-3 text-center mt-2'>
                                
                                <button
                                    className='btn btn-sm btn-success'
                                    onClick={(e) => saveReview(e, false)}
                                    disabled={displayWorking || ( ! commentToStudent.length && ! commentToInstructor.length )}
                                    >Save And Send To Instructor ONLY </button>
                            </div>
                            <div className='col-3 text-center mt-2'>
                                
                                <button
                                    className='btn btn-sm btn-info'
                                    onClick={(e) => saveReview(e, false, false)}
                                    disabled={displayWorking || ( ! commentToStudent.length && ! commentToInstructor.length && ! checkNoReview )}
                                    >Save And Do Not Send </button>
                            </div>
                            <div className='col-3 text-center mt-2'>
                                
                                <input
                                    type='checkbox'
                                    checked={checkNoReview}
                                    onClick={() => setCheckNoReview(!checkNoReview)}
                                    disabled={displayWorking }
                                    />
                                    <span className="ml-2">
                                        Cat Pic Checked
                                    </span>
                            </div>

                            <div className='col-12 text-center mt-2 bg-warning text-danger'>
                                <span className='mr-2'>
                                    Needs Follow-up: 
                                    <input 
                                        className='mr-3 ml-1'
                                        type='checkbox'
                                        onChange={()=> setNeedsFollowUp(!needsFollowUp)}
                                        checked={needsFollowUp}
                                    />
                                    ( set NEEDS REVIEW flag on LP3 manually )
                                </span>
                            </div>
                        </div>
                    </div>
                            <div className='col-12'>
                                {
                                    isGeneric &&
                                    <div className='bg-warning text-dark text-center'>
                                        This is a generic rubric for assignments with no rubrics.
                                        If you would like to help create a rubric for this or other assignments, please reach out to instructor Lee.
                                    </div>
                                }
                                <hr></hr>
                            </div>
                            <div className='col-12'>
                                <ReviewChecklist
                                    checklist={checklist}
                                    updateChecklist={setChecklist}
                                    setNeedsFollowUp={setNeedsFollowUp}
                                    setCommentToStudent={setCommentToStudent}
                                    setCommentToInstructor={setCommentToInstructor}
                                />
                            </div>
                            
                        </div>
                    }
                </div>
                
                {
                    displayDownloaderModal &&
                    <DownloaderModal
                        close={() => setDisplayDownloaderModal(false)}
                        assignmentDownloaderStatus={assignmentDownloaderStatus}
                        />
                }

                {
                    displayWorking &&
                    <LoadingSpinner isOverlay={true} />
                }
            </div>
            
        </>
    )
}

export default ReviewEditor;

const HistoryReview = ({
    reviewsHistory,
}) => {

    const { popOverlay } = useOverlays();
    
    return (
        <div className=' row layer layer-1 window_600'>
            {
                reviewsHistory
                    .sort( (a,b) => a.comment_date_added < b.comment_date_added ? 1 : -1 )
                    .map( review=> 
                    <div className='col-12 mb-2 layer layer-2 p-2 '>
                        Posted By: {review.poster}<br/>
                        Date: {review.comment_date_added}<br/>
                        Note to Student: <pre className='text-wrap'>{review.comment_text}</pre> <br/>
                    </div>
                )
            }

            <div className='col-12 text-center'>
                <button
                    className='btn btn-sm btn-info'
                    onClick={popOverlay}
                    >Close</button>
            </div>
        </div>
    )
}